














import Vue from "vue";
import { Component } from "vue-property-decorator";
import CSSModule from "@/store/modules/css-module";
import "material-design-icons/iconfont/material-icons.css";
import CargaDialog from "@/views/cargando/CargaDialog.vue";
@Component({
  components: {
    CargaDialog,
  },
})
export default class MainLayoutSinLogin extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;
  public route: any;
  public constructor() {
    super();
    this.bodystyle();
    Vue.prototype.$heightsection = this.$vuetify.breakpoint.height - 110;
  }

  public get animation_type() {
    this.route = this.$route;
    let animations = ["fade", "slide-fade", "bounce"];
    return animations[Math.floor(Math.random() * 3)];
  }
  public async bodystyle() {
    if ((process.env.VUE_APP_PRODUCTION as string) === "true") {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.body.style.paddingRight = "0px";
      } else {
        document.body.style.overflowX = "visible";
        document.body.style.paddingRight = "0px";
      }
    }
    if (!CSSModule.cssimportados) {
      var files: string[] = [];
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.common.css');
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.material.orange.light.compact.css');
      //files.push('https://kit-pro.fontawesome.com/releases/v5.13.0/css/pro.min.css');
      files.push(
        "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"
      );
      for (let i = 0; i < files.length; i++) {
        /* const element = files[i];
         var styles = "@import url('" + files[i] + "');";*/
        var newSS = document.createElement("link");
        newSS.rel = "stylesheet";
        newSS.href = files[i]; //'data:text/css,' + escape(styles);
        document.getElementsByTagName("head")[0].appendChild(newSS);
      }

      CSSModule.css_importados();
    }
  }
}
